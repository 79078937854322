import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { ScrollAnimation } from './ScrollAnimation';
import  SimpleContactForm from './SimpleContactForm';


function Hero() {
  const [scrollY, setScrollY] = useState(0);
  const arrowControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 50) {
      arrowControls.start({ opacity: 0, transition: { duration: 0.5 } });
    } else {
      arrowControls.start({ opacity: 1, transition: { duration: 0.5 } });
    }
  }, [scrollY, arrowControls]);

  return (
    <section className="text-center py-20 min-h-screen flex flex-col justify-center relative bg-black text-white overflow-hidden select-none">
      {/* Dot background */}
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 0.15,
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.8) 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.8) 100%)',
          
        }}
      ></div>

      {/* Content */}
      <div className="relative z-10">
        <ScrollAnimation>
          <motion.div
            className="w-24 h-24 bg-gray-300 rounded-full mx-auto mb-8 overflow-hidden"
            animate={{ scale: [0, 1.1, 1] }}
            transition={{ times: [0, 0.6, 1], duration: 1 }}
          >
            <img src="/images/profile-pic.jpg" alt="Profile" className="w-full h-full object-cover" />
          </motion.div>
        </ScrollAnimation>
        <ScrollAnimation>
          <h1 className="text-4xl md:text-5xl font-bold mb-2">
            Aspiring Cybersecurity Professional
          </h1>
        </ScrollAnimation>
        <ScrollAnimation>
          <h2 className="text-3xl md:text-5xl font-bold mb-6">
            Specializing in {' '}
            <motion.span
              style={{
                backgroundImage: 'linear-gradient(90deg, #ff4d4d, #ff9900, #ffcc00, #ff4d4d)',
                backgroundSize: '200% auto',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              animate={{ backgroundPosition: '200% center' }}
              transition={{ duration: 3, ease: 'linear', repeat: Infinity }}
            >
              Defensive Security
            </motion.span>
            .
          </h2>
        </ScrollAnimation>
        <ScrollAnimation>
          <p className="text-2xl mb-8 max-w-2xl mx-auto font-bold">
            I have a strong foundation in defensive security, and I have 2+ years of experience in various programming languages.
          </p>
        </ScrollAnimation>
        <ScrollAnimation>
          <div className="flex justify-center space-x-4 mb-8">
          <SimpleContactForm />
            <button className="px-8 py-3 bg-white text-black rounded hover:bg-gray-200 transition-colors">Download Resume</button>
          </div>
        </ScrollAnimation>
      </div>
      
      {/* Scroll down arrow */}
      <motion.div
        className="absolute bottom-16 left-1/2 transform -translate-x-1/2 z-10"
        animate={arrowControls}
        initial={{ opacity: 1 }}
      >
        <motion.svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
        >
          <motion.path
            d="M12 5V19M12 19L5 12M12 19L19 12"
            stroke="url(#arrow-gradient)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
          />
          <defs>
            <linearGradient id="arrow-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="red" />
              <stop offset="50%" stopColor="orange" />
              <stop offset="100%" stopColor="yellow" />
            </linearGradient>
          </defs>
        </motion.svg>
      </motion.div>
    </section>
  );
}

export default Hero;